import React from 'react'
import styled from "styled-components"
import { colors } from "Utilities"

export const Button = ({ 
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize 
}) => {
  return (
  
    <ButtonWrapper onClick={onClick} >
      {children}  
    </ButtonWrapper>
  )
  
}


const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 10px;
  border-color: ${colors.black};
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  max-width: 130px;
  background: ${colors.white}
`;