import React from "react"
import styled from "styled-components"
import { colors } from "Utilities"

const footerTextTitle = {
  color: "white",
  fontSize: "1rem"
};

const footerText = {
  color: "white",
  fontSize: "0.8rem",
  lineHeight: "0rem",
};

const infoText = {
  color: "white",
  fontSize: "0.8rem",
  textDecoration: "underline"
};

const copyright = {
  column: "1 / span 3",
  display: "flex",
  marginTop: "2rem",
  marginBottom: "1rem", 
  color: "white",
  fontSize: "0.6rem",

  flexGrow: "0",
};

const Footer = () => (
  <FooterWidthWrapper>
  
  <FooterWrapper>
  <OpeningHairWrapper>
      

        <p style={footerTextTitle}>Hair Salon Opening Hours</p>
        <p style={footerText}>Monday - 10AM-5PM</p>
        <p style={footerText}>Tuesday - 10AM-5PM</p>
        <p style={footerText}>Wednesday - 10AM-7.30PM</p>
        <p style={footerText}>Thursday - 10AM-7PM</p>
        <p style={footerText}>Friday - 10AM-3PM</p>
        <p style={footerText}>Saturday - 10AM-3PM</p>
        <p style={footerText}>Sunday - CLOSED</p>

    </OpeningHairWrapper>
    <ContactWrapper>
          

      <p style={footerTextTitle}>Storm Hair & Beauty</p>
      <p style={footerText}>171A High Street</p>
      <p style={footerText}>Street, Somerset, BA16 0NE</p>

      <br/>
      
      <a href="mailto:info@stormhairandbeauty.co.uk">
        <p style={infoText}>info@stormhairandbeauty.co.uk</p>
      </a>
      <a href="tel:01458448080">
        <p style={infoText}>+44 &#40;0&#41;1458 448080</p>
      </a>
      <p style={copyright}>&#169; Copyright 2022 - All Rights Reserved</p>
    </ContactWrapper>
    
    <OpeningBeautyWrapper>
      

        <p style={footerTextTitle}>Beauty Salon Opening Hours</p>
        <p style={footerText}>Monday - CLOSED</p>
        <p style={footerText}>Tuesday - 9AM-2PM & 5.30PM-8.30PM</p>
        <p style={footerText}>Wednesday - 10AM-4PM</p>
        <p style={footerText}>Thursday - 10AM-4PM</p>
        <p style={footerText}>Friday - 1.30AM-6.30PM</p>
        <p style={footerText}>Saturday - BIWEEKLY 10AM-4PM</p>
        <p style={footerText}>Sunday - CLOSED</p>

    </OpeningBeautyWrapper>
      
  </FooterWrapper>
  </FooterWidthWrapper>
)

export default Footer


const FooterWidthWrapper = styled.div`
  width: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  background: ${colors.black};
  width: 100%;
  @media (max-width: 769px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin-top: 0;
  }
`;

const OpeningHairWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 210px;
  line-height: 0rem;
  margin-left: 4rem;
  @media (max-width: 769px) {
    margin-top: 1rem;
    align-items: center;
    margin-left: 0;
  }
`;


const OpeningBeautyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 210px;
  line-height: 0rem;
  margin-right: 4rem;
  @media (max-width: 769px) {
    align-items: center;
    margin-right: 0;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 210px;
  line-height: 0rem;
  @media (max-width: 769px) {
    margin-top: 1rem;
    align-items: center;
    margin-right: 0;
  }
`;


