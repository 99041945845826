import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import navigationData from "./navigationData"


const RightNav = ({open}) => {
  const {menuLinks, title} = navigationData()
  
  return (
    <UL open={open}>
    
    {menuLinks.map(link => (
      <li
        key={link.name}
        style={{
          listStyleType: `none`,
          padding: `1rem`,
        }}
      >
        <Link style={{ color: `black` }} to={link.link}>
          {link.name}
        </Link>
      </li>
    ))}
    </UL>
  )
}

export default RightNav

const UL = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0;
  li {
    padding: 8px 10px;
    a {
      text-decoration: none;
      color: #000;
    }
  }

  @media (max-width: 1366px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    right: 0;
    bottom: 0;
    z-index: 20;
    height: 90vh;
    width: 400px;
    padding-top: 3.5rem;
    transition: transform 0.5s ease-in-out;
  }
`;