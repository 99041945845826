import React from 'react'
import { createGlobalStyle } from "styled-components"
import { colors } from "Utilities"
import styled from "styled-components"
import navigationData from "./navigationData"
import NewHeader from "./NewHeader"
import Footer from "../components/footer"

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    body {
      margin: 0;
      background: ${colors.lightGrey};

    }
  }
`


const Layout = ({ children }) => {
  
  const {menuLinks, title} = navigationData()
  return (
    <LayoutWrapper>
      <GlobalStyle />
        <HeaderWrapper>
        
          <NewHeader 
            menuLinks={menuLinks}
            siteTitle={title}
          />
        </HeaderWrapper>
        <main>{children}</main>
      <Footer />
    </LayoutWrapper>
  )
}

export default Layout

const LayoutWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  top: 0;
  position: relative;
  background: ${colors.lightGrey};
  z-index: 20;
`;