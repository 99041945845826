import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import Burger from "./Burger"
import { colors, elevation } from "Utilities"
import { Button } from "../Elements/Button"
import logo from "../images/storm-dark.png"
import instagramLogo from "../images/instagram.png"
import facebookLogo from "../images/facebook.png"


const logoLinkStyles = {
  backgroundImage: "none",
  textDecoration: "none",
};
const linkStyles = {
  display: "flex",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: 0,
  alignItems: "center",
  color: "black",
  fontSize: "0.8rem",
  textDecoration: "none",
};
const logoClass = {
  width: "200px",

  margin: "0",

};
const activeStyles = {
  textDecoration: "underline",
};

const instagramStyle = {
  width: "100%",
  height: "100%",
};
const facebookStyle = {
  width: "100%",
  height: "100%",
};

const timeoutLength = 300

class NewHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      mobileActiveClass: '',
      mouseOverButton: false,
      mouseOverMenu: false,
      mouseOverSubButton: false,
      mouseOverSubMenu: false,
    }
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState)
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    }
  }

  enterButton = () => {
    this.setState({ mouseOverButton: true })
  }

  leaveButton = () => {
    setTimeout(() => {
      this.setState({ mouseOverButton: false })
    }, timeoutLength)
  }

  enterMenu = () => {
    this.setState({ mouseOverMenu: true })
  }

  leaveMenu = () => {
    setTimeout(() => {
      this.setState({ mouseOverMenu: false })
    }, timeoutLength)
  }

  render() {

    const menuLinks = this.props.menuLinks

    return (
      <HeaderWrapper>
        <NavWrapper>
          <LogoWrapper>
            <Link to="/" style={{ logoLinkStyles }}>
              <img style={logoClass} src={logo} alt="Storm Hair and Beauty Logo" />
            </Link>
          </LogoWrapper>
          <ListWrapper>
            {menuLinks.map(link =>
              link.items ? (

                <ListItems key={link.name}>
                  <Link
                    onMouseEnter={this.enterButton}
                    onMouseLeave={this.leaveButton}
                    style={linkStyles}
                    to={link.link}

                  >
                    {link.name}
                  </Link>
                </ListItems>

              ) : (
                <ListItems key={link.name}>
                  <Link style={linkStyles} to={link.link} activeStyle={activeStyles}>
                    {link.name}
                  </Link>
                </ListItems>
              )
            )}
          </ListWrapper>
          <SocialWrapper>
            <InstagramWrapper>
              <a href="https://www.instagram.com/storm_hairandbeauty"
                target="_blank"
                rel="noopener noreferrer">
                <img style={instagramStyle} src={instagramLogo} alt="Instagram Logo and Link" />
              </a>
            </InstagramWrapper>
            <FacebookWrapper>
              <a href="https://www.facebook.com/stormhairstreet/"
                target="_blank"
                rel="noopener noreferrer">
                <img style={facebookStyle} src={facebookLogo} alt="Facebook Logo and Link" />
              </a>
            </FacebookWrapper>
            <Button>
              <a href="https://phorest.com/book/salons/stormhairdesignltd"
                target="_blank"
                rel="noopener noreferrer"
                style={linkStyles}>Book Now</a>
            </Button>

          </SocialWrapper>
          <DrawerToggleButtonWrapper>
            <Burger />
          </DrawerToggleButtonWrapper>

        </NavWrapper>
      </HeaderWrapper>
    )
  }
}



export default NewHeader

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background: ${colors.white}; 
  height: 90px;
  ${elevation[1]};

`;

const NavWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px, repeat(2, minmax(300px, 1fr));
  height: 100%;
  align-items: center;
  margin: 0 4rem;
  padding: 0;
  @media (max-width: 769px) {
    margin: 0 2rem;
    padding: 0;
  }
  @media (max-width: 469px) {
    margin: 0 1rem;
    padding: 0;
  }
  
`;

const DrawerToggleButtonWrapper = styled.div`
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (min-width: 1366px) {
    display:none;
  }
`

const LogoWrapper = styled.div`
  grid-column: 1;
  justify-content: flex-start;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  min-width: 250px;
  padding-top: 0.5rem;
`

const ListWrapper = styled.ul`
  grid-column: 2;
  list-style: none;
  margin: 0;
  padding: 1rem 0;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  @media (max-width: 1364px) {
    display:none;
  }
`;

const ListItems = styled.li`
  display: flex;
  margin: 1em;
  height: 30px;
  a {
    color: ${colors.black};
    text-decoration: none;
  }
`;

const SocialWrapper = styled.div`
  grid-column: 3;
  list-style: none;
  margin: 0;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  width: 100%;
  @media (max-width: 1364px) {
    grid-column: 2;
  }
  @media (max-width: 769px) {
    display: none;
  }
  
`;



const InstagramWrapper = styled.div`
  margin-right: 2rem;
  height: 40px;
  width: 40px;
  min-width: 40px;
`;

const FacebookWrapper = styled.div`
  margin: 0 2rem;
  margin-right: 8rem;
  padding: 2px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  @media (max-width: 1550px) {
    margin-right: 3rem;
  }
`;