import React, { useState } from "react"
import styled from "styled-components"
import RightNav from "./RightNav"
import navigationData from "./navigationData"

const Burger = () => {
  const [open, setOpen] = useState(false)
  const {menuLinks, title} = navigationData()

  return (
    <React.Fragment>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div/>
        <div/>
        <div/>
      </StyledBurger>
      <RightNav
      menuLinks={menuLinks}
      siteTitle={title} 
      open={open}
      />
    </React.Fragment>
  )
};

export default Burger;

const StyledBurger = styled.div `
  width: 2rem;
  height: 2rem;
  z-index: 25;
  display: none;

  @media (max-width: 1366px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 2px;
    background-color: ${({ open }) => open ? '#222' : '#222'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;