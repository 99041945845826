export const lightGrey = '#f1f7f7';
export const grey = '#323232';
export const black = '#222';
export const white = '#ffffff';
export const brightGreen = '#50e757';

export default {
  lightGrey,
  grey,
  black,
  white,
  brightGreen
};